<template>
	<div style="display:block" class="layer">
		<div class="layer__dim"></div>
		<div class="layer__full">
			<div class="layer__fullbox">
				<div class="layer__fullheader">
					<nav class="nav">
						<div class="nav__wrap">
							<a @click="goBack" class="nav__btn nav__btn--prev">
								<i class="icon icon-close">닫기</i><!-- 재번역체크 -->
							</a>
							<p class="nav__item">
								<span class="nav__text">
									{{t('10046')}}
								</span>
							</p>
							<a class="nav__btn nav__btn--map">
							</a>
						</div>
					</nav>
				</div>
				<div class="layer__fullbody">
					<div class="row">
						<div class="terms__wrap">
							<div class="terms__version">
								 <div class="slt">
									<input type="text" class="ipt-frm ipt-slt" :value="version ? version : ''" readonly ref="versionInput" @focus="onFocusVersion(!isFocusVersion)">
									<div class="frms__btns" @click="onFocusVersion(!isFocusVersion)">
										<button type="button" class="btn-frmreset"><i class="icon icon-down"></i>
										</button>
									</div>
									<ul class="slt__list" :class="{'slt__list-show': isFocusVersion}">
										<li v-for="(item, index) in mcs01" :key="index" @click="onClickItem(item)">
											{{item.VERSION}}
										</li>
									</ul>
								</div> 
							</div>
							<!-- 1 -->
							<div class="terms__box" v-if="mcs01 !== undefined" style="white-space: pre-wrap;">
								{{versiontext ? versiontext :  mcs01[0].CONTESTS}}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { useRouter } from 'vue-router'
import { useStore } from "vuex"
import { onMounted, computed, ref } from "vue"
import { useI18n } from 'vue-i18n' 
import i18n from "@/i18n"; 
export default {
	setup: function() {
		const store = useStore();
		const router = useRouter()

		const mcs01 = computed(() => store.state.mcs01.mcs01["05"]);

		const isFocusVersion = ref(false);
		const versionInput = ref(null);
		const version = ref("1.0");
		const versiontext = ref("");
		const { t }= useI18n() 
		const onFocusVersion = (isFocus) => {
			if(isFocus){
				versionInput.value.focus();
			}
			isFocusVersion.value = isFocus;
		};

		const onClickItem = (item) => {
			version.value = item.VERSION;
			versiontext.value = item.CONTESTS;
			isFocusVersion.value = false;
		};

		onMounted(() => {
			store.dispatch("mcs01/fetchMcs01", {
				proc_cd : "05"
			});
		});

		const goBack = () => {
      router.go(-1)
		}
		
		return {
			store,
			router,
			goBack,
			mcs01,
			versionInput,
			versiontext,
			onFocusVersion,
			isFocusVersion,
			version,
			t,  //번역필수 모듈
		  i18n,
			onClickItem
		}
	},
};
</script>